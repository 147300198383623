import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseConfig from './firebaseConfig';
import DataProvider from './provider';

import SignIn from './SignIn';
import Home from './pages/Home';
import Me from './pages/Me';
import ScanCode from './pages/ScanCode';
import SellingArea from './pages/sellingArea/Index';
import PaymentInstruments from './pages/payment/Instruments';
import BraintreeAddCC from './pages/payment/integrations/Braintree/AddCard';
import Menu from './pages/Menu';
import IFrameMenu from './pages/sellingArea/IFrameMenu';
import Loading, { LoadingContainer } from './Loading';
import Payment from './pages/payment/Payment';
import Order from './pages/order/Order';
import Wallet from './pages/wallet';
import WalletRecharge from './pages/wallet/recharge/Recharge';
import WalletRechargeInfo from './pages/wallet/recharge/RechargeInfo';
import PellegriniPay from './pages/payment/integrations/Pellegrini/PellegriniPay';
import SodexoPay from './pages/payment/integrations/Sodexo/SodexoPay';
import PickPaymentOptions from './pages/payment/PickPaymentOptions';
import OrderList from './pages/orderList/OrderList';
import ThreeDSecurePayment from './pages/payment/integrations/Braintree/3dSecure';
import EdenredTicketAdd from './pages/payment/integrations/Edenred/Ticket/Add';
import EdenredTicketAddConfirm from './pages/payment/integrations/Edenred/Ticket/AddConfirm';
import EdenredTicketPay from './pages/payment/integrations/Edenred/Ticket/Pay';
import CoffeeSellingArea from './pages/coffee/SellingArea';
import CoffeeCollectNickName from './pages/coffee/CollectNickName';
import CoffeeSellingOrder from './pages/coffee/SellingOrder';
import CoffeeInsufficientFund from './pages/coffee/CoffeeInsufficientFund';
import LoginWithEmailPassword from './pages/LoginWithEmailPassword';
import LoginWithToken from './pages/LoginWithToken';
import BraintreeAddPayPal from './pages/payment/integrations/Braintree/AddPayPal';
import DeviceDataPayPal from './pages/payment/integrations/Braintree/DeviceDataPayPal';
import { Navigation } from './models';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { pushData, pushTrackingEvent } from './utils/gtmDataLayer';
import PrivacyBanner from './pages/PrivacyBanner';
import SatispayStandardAdd from './pages/payment/integrations/Satispay/Standard/Add';
import SatispayTicketRecharge from './pages/payment/integrations/Satispay/Ticket/Pay';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const theme = createMuiTheme({
  palette: {
	common: {
	  black: "#000",
	  white: "#fff",
	},
	primary: {
	  main: '#086d40',
	},
	secondary: {
	  main: '#a1d97b',
	}
  },
  typography: {
	fontFamily:  [
		'"Montserrat"',
		'"Arial"',
	  ].join(',')
  }
});

interface AppState {
	user: any,
	loading: boolean,
	navigation: Navigation | null;
	firebaseStatus: 'AWAITING' | 'READY';
}

class App extends Component {

	unsubscribeAuthStateListener: any;
	state: AppState;

	constructor(props: any) {
		super(props);
		this.unsubscribeAuthStateListener = () => {};
		this.state = {
			user: null,
			loading: true,
			navigation: null,
			firebaseStatus: 'AWAITING'
		}
	}

	componentDidMount() {
		let path = window.location.pathname;
		let title = window.document.title;
		let search = window.location.search !== '' ? window.location.search : '';

		if(path !== "/") {
			window.history.replaceState(null, "Foorban", "/");
			window.history.pushState(null, title, `${path}${search}`);
		}

		this.unsubscribeAuthStateListener = 
			onAuthStateChanged(auth,  (user: any) => {
				if(this.state.firebaseStatus === 'READY') {
					return;
				}
				this.setState({
					firebaseStatus: 'READY',
					user,
					loading: true,
					navigation: null,
				})
			},
			(err: any) => {
				console.log(err);
			}
		);
		pushData('appBuildNum', process.env.REACT_APP_CIRCLE_BUILD_NUM);
		pushTrackingEvent({
			type: 'TrackingEvent',
			data: {
				category: 'reactApp',
				action: 'componentDidMount',
				label: process.env.REACT_APP_CIRCLE_BUILD_NUM || '',
			}
		})
	}

	componentWillUnmount() {
		this.unsubscribeAuthStateListener();
	}

	setNavigation(navigation: Navigation) {
		this.setState({
			navigation,
			loading: false,
			user: this.state.user,
			firebaseStatus: 'READY',
		})
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				
				<Router>
					<Switch>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/locker-selling-order/:id">
							<Order user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></Order>
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/selling-area/:id">
							<SellingArea user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></SellingArea>
						</PrivateRoute>
						<Route path="/login">
							<SignIn user={this.state.user} loading={this.state.loading}></SignIn>
						</Route>
						<Route path={["/code-landing/:code", "/code-landing"]}>
							<ScanCode private={false}></ScanCode>
						</Route>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/me">
							<Me user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></Me>
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/code">
							<ScanCode private={true} user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></ScanCode>
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/lista-ordini">
							<OrderList user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/edenred_ticket/add/confirm">
							<EdenredTicketAddConfirm user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/edenred_ticket/add">
							<EdenredTicketAdd user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/edenred_ticket/pay/:uuid">
							<EdenredTicketPay user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/braintree_cc/add">
							<BraintreeAddCC user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></BraintreeAddCC>
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/braintree_paypal/add">
							<BraintreeAddPayPal user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/braintree_paypal/device_data/:uuid">
							<DeviceDataPayPal user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/pellegrini/pay/:uuid">
							<PellegriniPay user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/sodexo/pay/:uuid">
							<SodexoPay user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/payment-options/:context">
							<PickPaymentOptions user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/braintree_cc/3dsecure-flow/:uuid">
							<ThreeDSecurePayment user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/satispay_standard/add">
							<SatispayStandardAdd user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/integration/satispay_ticket/pay/:uuid">
							<SatispayTicketRecharge user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment/:uuid">
							<Payment user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></Payment>
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/payment">
							<PaymentInstruments user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></PaymentInstruments>
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/wallet/recharge/:uuid">
							<WalletRechargeInfo user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/wallet/recharge">
							<WalletRecharge user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/wallet">
							<Wallet user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<Route path="/menu/:UUID">
							<Menu user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></Menu>
						</Route>
						<Route path="/iframe-menu/:UUID">
							<IFrameMenu user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</Route>
						<Route path="/login-with-email">
							<LoginWithEmailPassword user={this.state.user} loading={this.state.loading} />
						</Route>
						<Route path="/login-with-token">
							<LoginWithToken user={this.state.user} loading={this.state.loading} />
						</Route>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/coffee/selling-area/:id">
							<CoffeeSellingArea user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/coffee/selling-order/:id">
							<CoffeeSellingOrder user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/coffee/collect-nick-name">
							<CoffeeCollectNickName user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/coffee/insufficient-fund">
							<CoffeeInsufficientFund user={this.state.user} loading={this.state.loading} navigation={this.state.navigation} />
						</PrivateRoute>
						<Route path="/privacy-policy">
							<PrivacyBanner />
						</Route>
						<PrivateRoute firebaseStatus={this.state.firebaseStatus} setNavigation={this.setNavigation.bind(this)} path="/">
							<Home user={this.state.user} loading={this.state.loading} navigation={this.state.navigation}></Home>
						</PrivateRoute>
					</Switch>
				</Router>
			</ThemeProvider>
		);
	}
}
const useStyles = makeStyles((theme: Theme) => ({
	text: {
		padding: theme.spacing(0, 0, 2),
		color: 'white'
	}
}));

function PrivateRoute({ children, ...rest } : any) {
	const [status, setStatus] = useState<'INITIAL' | 'GETTING_NAVIGATION' | 'RETRY_GETTING_NAVIGATION' | 'ERROR_GETTING_NAVIGATION' | 'GETTING_NAVIGATION_DONE' | 'RENDER'>('INITIAL');
	const classes = useStyles();

	useEffect(() => {
		if(['INITIAL'].includes(status)) {
			setStatus('GETTING_NAVIGATION')
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
				dataProvider(`b2c/navigation`, undefined, { avoidStatusChecks: true})
					.then((navigation: {
						status: number;
						statusText: string;
						headers: Headers;
						json: any;
					}) => {
						if([200, 201].includes(navigation.status)) {
							rest.setNavigation(navigation.json);
							setStatus('GETTING_NAVIGATION_DONE')
						} else {
							setStatus('RETRY_GETTING_NAVIGATION')
						}
					})
					.catch((e) => {
						setStatus('ERROR_GETTING_NAVIGATION');
					})
		}
		if(rest.firebaseStatus ===  'READY' && ['RETRY_GETTING_NAVIGATION'].includes(status)) {
			setStatus('GETTING_NAVIGATION')
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
				dataProvider(`b2c/navigation`)
					.then(navigation => {
						!children.props.user && pushTrackingEvent({
							type: "TrackingEvent",
							data: {
								category:"Login", action:"Logged", label: "onlyCookies",
							}
						});
						rest.setNavigation(navigation);
						setStatus('GETTING_NAVIGATION_DONE')
					})
					.catch((e) => {
						setStatus('ERROR_GETTING_NAVIGATION');
					})
		}
		if(status === 'GETTING_NAVIGATION_DONE' && children.props.navigation) {
			setStatus('RENDER');
		}
	}, [children.props, status, rest])

	return (
	  <Route
		{...rest}
		render={() => {
			if(['INITIAL', 'GETTING_NAVIGATION', 'GETTING_NAVIGATION_DONE', 'RETRY_GETTING_NAVIGATION'].includes(status)) {
				return <Loading />;
			}

			if(['ERROR_GETTING_NAVIGATION'].includes(status)) {
				return (
					<LoadingContainer>
						<Typography className={classes.text} align="center">Ops! Sembra che qualcosa sia andato storto.</Typography>
						<Button
							fullWidth
							onClick={() => {
								setStatus('RETRY_GETTING_NAVIGATION');
							}}
							size="large"
							variant="contained">
								Riprova
						</Button>
					</LoadingContainer>
				);
			}

			if(['RENDER'].includes(status)) {
				return children;
			}
		}}
	  />
	);
}

export default App;