import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Funnel from '../../../templates/contents/Funnel';
import Modal from '../../../templates/contents/Modal';
import DataProvider from '../../../provider';
import { PreferenceResponse, WalletRechargeInfo } from '../interfaces';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import { InputAdornment, TextField } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';

const useStyles = makeStyles((theme: Theme) => ({
	input: {
		padding: theme.spacing(2, 0, 0),
	},
	button: {
		margin: theme.spacing(2, 0, 0),
	},
	container: {
		padding: theme.spacing(4, 2),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	text: {
		padding: theme.spacing(2, 0),
	},
	card: {
		width: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 16,
	},
	icon: {
		margin: theme.spacing(0, 0, 0, 2)
	},
	full: {
		width: '100%',
	},
	image: {
		width: '80px',
	},
	rechargeValue: {
		display: 'flex',
		flex: 1,
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	buttonText: {
		minWidth: '35px',
	},
	title: {
		width: '100%',
		padding: theme.spacing(0, 0, 1)
	}
}));

type WalletRechargeStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING' | 'SUBMIT_DATA' | 'SUBMITTING_DATA' | 'SUBMIT_ERROR' | 'WAITING_ACTION' | 'AWAIT_CUSTOM_INPUT';

export default function WalletRecharge (props: any) {
    const [status, setStatus] = useState<WalletRechargeStatus>('FETCH_DATA');
	const [walletRechargeData, setWalletRechargeData] = useState<PreferenceResponse>();
	const [amount, setAmount] = useState<number>(0);
	const [customAmount, setCustomAmount] = useState<any>(25);
	let location = useLocation();
    const mySearchParams = new URLSearchParams(location.search);

    const history = useHistory();
	const classes = useStyles();

    useEffect(() => {
		if(status === 'FETCH_DATA') {
			setStatus('FETCHING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/wallet-recharge`)
				.then((data: PreferenceResponse) => {
					if(data.amountType === null && data.payWith === null) {
						if(mySearchParams.get('payWithNull') === 'yes') {
							const returnUrl = mySearchParams.get('return-url');
							if(returnUrl) {
								history.replace(returnUrl);
							} else {
								history.goBack();
							}
						} else {
							mySearchParams.append('payWithNull', 'yes');
							history.replace(`${location.pathname}?${mySearchParams.toString()}`);
							const params = new URLSearchParams();
							params.set('key-to-update', 'RECHARGE_PICKED_PI');
							params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));
							history.push(`/payment/payment-options/walletRechargeMethod?${params.toString()}`, {
								key_to_update: 'RECHARGE_PICKED_PI',
							});
						}
					} else {
						if(['TargetAmount', 'DefinedAmount'].includes(data.amountType)) {
							setAmount(25);
						}
						setStatus('DATA_FETCHED');
						setWalletRechargeData(data);
					}
				})
				.catch(e => {
					setStatus('ERROR_DATA_FETCHING');
				})
		}
	}, [history, location.pathname, mySearchParams, status]);

	useEffect(() => {
		if(status === 'SUBMIT_DATA') {
			setStatus('SUBMITTING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/wallet-recharge`, {
				method: 'POST',
				body: JSON.stringify({
					amount,
					walletRechargeData,
					resultUrl: mySearchParams.get('return-url') || null,
				})
			})
			.then((data: WalletRechargeInfo) => {
				switch (data.actionType) {
					case 'redirect':
						if(data.linkAction?.type === 'internal' && data.linkAction?.link) {
							history.push(data.linkAction?.link);
							break;
						} else if(data.linkAction?.link){
							window.location.href = data.linkAction?.link;
							break;
						}
						break;
					case 'loading':
						setStatus('WAITING_ACTION');
						break;
					case 'error':
					case 'cancelled':
						setStatus('SUBMIT_ERROR');
						break;
					default:
						throw new Error("");
				}
			})
			.catch(e => {
				setStatus('SUBMIT_ERROR');
			});
		}
	}, [amount, walletRechargeData, history, status, mySearchParams]);

    return <>
	<Funnel title={{
		'FETCH_DATA': 'Caricamento...',
		'FETCHING_DATA': 'Caricamento...',
		'DATA_FETCHED': 'Ricarica Wallet',
		'AWAIT_CUSTOM_INPUT': 'Ricarica Wallet',
		'SUBMIT_DATA': 'Invio dati...',
		'SUBMITTING_DATA': 'Invio dati...',
		'SUBMIT_ERROR':  'Errore invio dati',
	}[status] || 'Errore caricamento dati'} loading={[
		'FETCH_DATA',
		'FETCHING_DATA',
		'SUBMIT_DATA',
		'SUBMITTING_DATA',
	].includes(status)}>
        <Container className={classes.container} component="main" maxWidth="xs">
			 {status === 'ERROR_DATA_FETCHING' && <>
				<Typography className={classes.text} align="center" variant="subtitle1">Ops.. C'è stato un errore durante il caricamento dei dati.</Typography>
				<Button fullWidth size='large' onClick={() => {
					setStatus('FETCH_DATA')
				}} variant="contained" color="primary">
					Ricarica Pagina
				</Button>
			</>}
			{status === 'WAITING_ACTION' && <>
				<Typography  className={classes.text} align="center" variant="subtitle1">Stiamo elaborandi i dati inviati.</Typography>
				<Button fullWidth size='large' onClick={() => {
					setStatus('FETCH_DATA')
				}} variant="contained" color="primary">
					Ricarica Pagina
				</Button>
			</>}
			{status === 'SUBMIT_ERROR' && <>
				<Typography  className={classes.text} align="center" variant="subtitle1">Errore durante l'elaborazione della richiesta.</Typography>
				<Button fullWidth size='large' onClick={() => {
					setStatus('FETCH_DATA')
				}} variant="contained" color="primary">
					Ricarica Pagina
				</Button>
			</>}
            {status === 'DATA_FETCHED' && walletRechargeData && <>
				<Typography color="textSecondary" className={classes.title}>Metodo di ricarica</Typography>
				<GenericPaymentCard
					data={walletRechargeData.payWith}
				/>
				{['TargetAmount', 'DefinedAmount'].includes(walletRechargeData.amountType) && <div className={classes.rechargeValue}>
					<Button className={classes.button} onClick={() => {
						setAmount(5);
					}} size="large" variant={"contained"} color={amount === 5 ? "primary": "default"}><span className={classes.buttonText}>5 €</span></Button>
					<Button className={classes.button} onClick={() => {
						setAmount(10);
					}} size="large" variant={"contained"} color={amount === 10 ? "primary": "default"}><span className={classes.buttonText}>10 €</span></Button>
					<Button className={classes.button} onClick={() => {
						setAmount(25);
					}} size="large" variant={"contained"} color={amount === 25 ? "primary": "default"}><span className={classes.buttonText}>25 €</span></Button>
					<Button className={classes.button} onClick={() => {
						setCustomAmount(amount);
						setStatus('AWAIT_CUSTOM_INPUT');
					}} size="large" variant={"contained"} color={"default"}><span className={classes.buttonText}>...</span></Button>
				</div>}
				<Button fullWidth className={classes.button} onClick={() => {
					setStatus('SUBMIT_DATA');
				}} variant="contained" color="primary" size="large">Ricarica Wallet</Button>
			</>}
        </Container>
    </Funnel>
	{status === "AWAIT_CUSTOM_INPUT" && <Modal title="Importo specifico" loading={false} action={() => {
		setStatus('DATA_FETCHED')
	}}>
		<Container maxWidth="xs">
			<TextField
				size='medium'
				type='number'
				onChange={(event) => {
					setCustomAmount(event.target.value);
				}}
				value={customAmount}
				inputProps={{step: "0.1", lang:"en-US"}}
				autoFocus
				fullWidth
				variant="outlined"
				InputProps={{
					endAdornment: (
						<InputAdornment position="start">
							<EuroIcon color='primary'/>
						</InputAdornment>
					)
				}}
			/>
			<Button disabled={!customAmount || isNaN(parseFloat(customAmount)) || parseFloat(customAmount) < 1} fullWidth className={classes.button} onClick={() => {
				if(customAmount && !isNaN(parseFloat(customAmount)) && parseFloat(customAmount) >= 1) {
					setAmount(parseFloat(customAmount));
					setStatus('SUBMIT_DATA');
				}
			}} variant="contained" color="primary" size="large">Ricarica Wallet</Button>
		</Container>
    </Modal>}
	</>;
}

function GenericPaymentCard(props: {
	data: {
		label: string;
		provider?: string;
	};
}) {
	const classes = useStyles();
	const history = useHistory();
	return (
		<ButtonBase className={classes.full} onClick={() => {
			const params = new URLSearchParams();
			params.set('key-to-update', 'RECHARGE_PICKED_PI');
			params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));
			history.push(`/payment/payment-options/walletRechargeMethod?${params.toString()}`, {
				key_to_update: 'RECHARGE_PICKED_PI',
			});
		}}>
			<Card className={classes.card}>
				{props.data.provider &&
					<img alt={props.data.provider} className={classes.image} src={`https://images.foorban.business/payment/${props.data.provider.toLowerCase()}.svg`}></img>
				}
				<div className={classes.full}>
					<Typography align="center" variant="h6">
						{props.data.label}
					</Typography>
				</div>
				<Typography className={classes.icon} align="center" variant="body2" component="p">
					CAMBIA
				</Typography>
			</Card>
		</ButtonBase>
	);
}