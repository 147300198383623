import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import DataProvider from '../../../../../provider';
import Container from '@material-ui/core/Container';
import Funnel from '../../../../../templates/contents/Funnel';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { MatchCodeRedirectResponse } from '../types';

type STATE =
| 'REQUEST_REDIRECT'
| 'REQUESTING_REDIRECT'
| 'ERROR_REQUESTING_REDIRECT'
| 'REDIRECT_READY'
| 'MAX_REQUEST_REDIRECT';

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        margin: theme.spacing(4, 0, 2),
    },
    button: {
        margin: theme.spacing(0, 2, 1, 2),
    }
}));

export default function Pay(props: any) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get('from');

	const params = useParams();
	const uuid = params['uuid'];

    const [state, setState] = useState<STATE>('REQUEST_REDIRECT');
    const history = useHistory();
    
    const [lastError, setLastError] = useState<string>();
    const [retryCount, setRetryCount] = useState<number>(0);
    const [redirect, setRedirect] = useState<string>();

    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const classes = useStyles();

    useEffect(() => {
        let timeout: null | NodeJS.Timeout = null;

        if(state === 'REQUEST_REDIRECT') {
            if(retryCount < 10) {
                setState('REQUESTING_REDIRECT');

                dataProvider(`b2c/payment/integration/satispay/ticket/pa/${uuid}/get-match-code-redirect`, {
                    method: 'GET',
                })
                    .then((response: MatchCodeRedirectResponse) => {
                            if(!response) {
                                throw new Error();
                            }
    
                            if('error' in response) {
                                setLastError(response.error);
                                setState('ERROR_REQUESTING_REDIRECT');
                                return;
                            }
    
                            if(!response.data) {
                                throw new Error();
                            }
                            
                            if('loading' in response.data) {
                                timeout = setTimeout(() => {
                                    setRetryCount(retryCount + 1);
                                    setState('REQUEST_REDIRECT');
                                }, 1000);
                            } else if('redirect' in response.data) {
                                if(response.data.redirect.type ===  'external') {
                                    setState('REDIRECT_READY');
                                    setRedirect(response.data.redirect.link);
                                } else if(response.data.redirect.type === 'internal') {
                                    history.replace(decodeURIComponent(response.data.redirect.link));
                                }
                            }
     
                    })
                    .catch(() => {
                        setLastError('');
                        setState('ERROR_REQUESTING_REDIRECT');
                    })
            } else {
                setState('MAX_REQUEST_REDIRECT');
            }

        }

        return () => {
			if(timeout) {
				clearTimeout(timeout);
			}
		}
	}, [state, dataProvider, history, uuid, from, retryCount]);

    useEffect(() => {
		const handleMessage = () => {
            if (!document.hidden && ['MAX_REQUEST_REDIRECT', 'REDIRECT_READY'].includes(state)){
                setState('REQUEST_REDIRECT');
                setRetryCount(0)
            }
        }

		document.addEventListener("visibilitychange", handleMessage, false);

		return () => {
			document.removeEventListener("visibilitychange", handleMessage);
		}
	});

    if(window.opener) {
        window.close();
        return null;
    }

    return (
        <Funnel title={`Paga con Satispay`} loading={!['REDIRECT_READY', 'MAX_REQUEST_REDIRECT', 'MAX_REQUEST_REDIRECT'].includes(state)}>
            <Container component="main" maxWidth="xs">
                {state === 'REDIRECT_READY' && <>
                    <Typography align="center" className={classes.text}>Cliccando su continua sarai reindirizzato sulla pagina del nostro partner Satispay, segui le istruzioni.</Typography>
                    <Button fullWidth size="large"variant="contained" color="primary" onClick={() => window.open(redirect)}>Continua</Button>
                </>}
                {state === 'MAX_REQUEST_REDIRECT' && <>
                    <Typography align="center" className={classes.text}>Verifica account satispay in corso...</Typography>
                    <Button fullWidth size="large"variant="contained" color="primary" onClick={() => {
                        setState('REQUEST_REDIRECT');
                        setRetryCount(0)
                    }}>Riprova</Button>
                </>}
                {state === 'ERROR_REQUESTING_REDIRECT' && <>
                    <Typography align="center" className={classes.text}>{lastError || `Ops! Sembra che qualcosa sia andato storto.`}</Typography>
                    <Button fullWidth size="large"variant="contained" color="primary" onClick={() => {
                        setState('REQUEST_REDIRECT');
                        setRetryCount(0)
                    }}>Riprova</Button>
                </>}
            </Container>
        </Funnel>
    );
}